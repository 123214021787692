export const trabalhistaFetchData = async (requestData) => {
    try {

      const apiUrl = process.env.REACT_APP_API_URL;

      const response = await fetch(apiUrl+'/calculationTrabalhista', {
        method: 'POST', // ou 'GET', dependendo do método necessário
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      if (!response.ok) {
        throw new Error('Erro na requisição');
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erro na requisição:', error);
      throw error;
    }
  };