import React from "react";
import PropTypes from 'prop-types';
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
} from "@material-tailwind/react";
import {
  CalculatorIcon,
  HomeIcon,
  NewspaperIcon,
} from "@heroicons/react/24/solid";
import { logEvent, analytics }  from '../firebase';


function MenuLateral({ selectedTab, setSelectedTab, setMenuVisible}) {
  return (
    <Card className="h-[calc(100%)] w-full max-w-[20rem] min-w-[18rem] p-4 shadow-x5 " style={{ backgroundColor: 'rgb(12, 44, 69)', borderRadius: '0' }}>
      <div className="flex justify-center items-center">
        <div className="text-center">
          <img src="/logo512.png" alt="brand" className="h-12 w-12 mx-auto mb-1" />
          <Typography variant="h5" className="text-white ">
            DR CÁLCULO
          </Typography>
          <Typography variant="h6" className="text-white">
            Jurídico
          </Typography>
        </div>
      </div>

      <List>

        <ListItem  onClick={() =>{
            setSelectedTab("Home")
            setMenuVisible(false)
            logEvent(analytics, 'goTo(Home)',);

           }}>
        <ListItemPrefix>
            <HomeIcon className="h-5 w-5 text-white" />
          </ListItemPrefix>
          <Typography 
            color="blue-gray" 
            variant="h6"
            className={`mr-auto font-normal text-white ${selectedTab === "Home" ? "font-bold" : ""}`}>
            Home
          </Typography>
        </ListItem>

        <ListItem onClick={() => {
          setSelectedTab("Atualização Monetária")
          setMenuVisible(false)
          logEvent(analytics, 'goTo(Atualização Monetária)',);
          }}>
          <ListItemPrefix>
            <CalculatorIcon className="h-5 w-5 text-white" />
          </ListItemPrefix>
          <Typography 
            color="blue-gray" 
            variant="h6"
            className={`mr-auto font-normal  text-white ${selectedTab === "Atualização Monetária" ? "font-bold" : ""}`}>
            Atualização Monetária
          </Typography>
        </ListItem>

        <ListItem onClick={() => {
          setSelectedTab("News")
          setMenuVisible(false)
          logEvent(analytics, 'goTo(Últimas Notícias)',);
          }}>
          <ListItemPrefix>
            <NewspaperIcon className="h-5 w-5 text-white" />
          </ListItemPrefix>
          <Typography 
            color="blue-gray" 
            variant="h6"
            className={`mr-auto font-normal text-white ${selectedTab === "News" ? "font-bold" : ""}`}>
            Últimas Notícias
          </Typography>
        </ListItem>
      </List>
    </Card>
  );
}

MenuLateral.propTypes = {
  selectedTab: PropTypes.string.isRequired,
  setSelectedTab: PropTypes.func.isRequired,
};

export default MenuLateral;