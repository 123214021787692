import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Typography } from "@material-tailwind/react";
import { trabalhistaFetchData } from '../request/calculationTrabalhista';


function Trabalhista() {

  // Seus estados para os valores dos campos
  const [dataInicio, setDataInicio] = React.useState('2022-05-20');
  const [dataFim, setDataFim] = React.useState('2023-03-15');
  const [salario, setSalario] = React.useState(2500);
  const [indicePorcentagem, setIndicePorcentagem] = React.useState(10);
  const [multaPorcentagem, setMultaPorcentagem] = React.useState(5);
  const [porcentagemHonorarios, setPorcentagemHonorarios] = React.useState(20);
  const [rows, setRows] = React.useState([]);
  const [totalValue, setTotalValue] = React.useState(0);
  const [totalHonorario, setTotalHonorario] = React.useState(0);


  const indices = [
    { name: 'Índice 1', value: '0.95' },
    { name: 'Índice 2', value: '1.02' },
    { name: 'Índice 3', value: '0.98' },
    // Adicione mais índices conforme necessário
  ];

  const calculateValues = async () => {
    try {
      // Obtenha os dados necessários do estado para enviar na requisição
      const requestData = {
        dataInicio,
        dataFim,
        salario,
        indicePorcentagem,
        multaPorcentagem,
        porcentagemHonorarios,
      };

      // Faça a requisição
      const responseData = await trabalhistaFetchData(requestData);

      // Atualize o estado da tabela com os dados da resposta
      const updatedRows = responseData.resultado.data.map(item =>
        createData(
          item.competencia,
          item.diasTrabalhados,
          item.indiceValor,
          item.multaValor,
          item.salarioMes,
          item.salarioAtualizado
        )
      );

      // Atualize o estado da tabela com os novos dados
      setRows(updatedRows);
      setTotalValue(responseData.resultado.totalSalariosAtualizados)
      setTotalHonorario(responseData.resultado.honorarios)

    } catch (error) {
      console.log(error)
      // Trate os erros aqui, se necessário
    }
  };

  // Função para criar um objeto de dados no formato desejado
  function createData(competencia, diasTrabalhados, indiceValor, multaValor, salarioMes, salarioAtualizado) {
    return { competencia, diasTrabalhados, indiceValor, multaValor, salarioMes, salarioAtualizado };
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      calculateValues();
    }
  };

  return (

    <div style={{
      textAlign: 'center',
      margin: '50px auto'
    }}>
      <Typography variant="h3" color="blue-gray">
        Cálculo Trabalhista
      </Typography>
      <div>
        <Box>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <InputField
              label="Salário"
              value={salario}
              type="number"
              onChange={(e) => setSalario(e.target.value)}
            />
            <InputField
              label="Data de Início"
              value={dataInicio}
              type="date"
              onChange={(e) => setDataInicio(e.target.value)}
            />
            <InputField
              label="Data de Fim"
              value={dataFim}
              type="date"
              onChange={(e) => setDataFim(e.target.value)}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <PercentageInputField
              label="Índice"
              value={indicePorcentagem}
              onChange={(e) => setIndicePorcentagem(e.target.value)}
            />
            <PercentageInputField
              label="Multa"
              value={multaPorcentagem}
              onChange={(e) => setMultaPorcentagem(e.target.value)}
            />
            <PercentageInputField
              label="Honorários"
              value={porcentagemHonorarios}
              onChange={(e) => setPorcentagemHonorarios(e.target.value)}
            />
          </div>
        </Box>
      </div>

      <Button sx={{ marginTop: '10px' }} onClick={calculateValues} variant="contained">Executar Cálculo</Button>

      <Box sx={{ display: 'flex', marginTop: '100px' }}>
        <TableContainer component={Paper}  >
          <Table sx={{ minWidth: 500, maxWidth: 700 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Competência</TableCell>
                <TableCell align="center">Dias Trabalhados</TableCell>
                <TableCell align="center">Salário</TableCell>
                <TableCell align="center">Índice</TableCell>
                <TableCell align="center">Multa</TableCell>
                <TableCell align="center">Salário Atualizado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.competencia} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="center">{row.competencia}</TableCell>
                  <TableCell align="center">{row.diasTrabalhados}</TableCell>
                  <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.salarioMes)}</TableCell>
                  <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.indiceValor)}</TableCell>
                  <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.multaValor)}</TableCell>
                  <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.salarioAtualizado)}</TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell colSpan={5} align="left"><strong>Honorários:</strong></TableCell>
                <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalHonorario)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5} align="left"><strong>Total Salários Atualizados:</strong></TableCell>
                <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValue)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5} align="left"><strong>Total:</strong></TableCell>
                <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(parseFloat(totalHonorario)+parseFloat(totalValue))}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>

  );
};

export default Trabalhista;


const InputField = ({ label, value, type, onChange }) => {
  return (
    <TextField
      label={label}
      type={type}
      value={value}
      onChange={onChange}
      fullWidth
      margin="normal"
    />
  );
};

const PercentageInputField = ({ label, value, onChange }) => {
  return (
    <InputField
      label={`${label} (%)`}
      type="number"
      value={value}
      onChange={onChange}
    />
  );
};