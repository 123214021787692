import React from 'react';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import '../css/LandingPage1.css';
import { logEvent, analytics } from '../firebase';

import imageLp1 from '../landingpage1_1.png'
import imageLp2 from '../landingpage1_2.png'
import imageLp3 from '../landingpage1_3.png'
import imageLp4 from '../landingpage1_4.png'
import imageLp5 from '../landingpage1_5.png'
import imageLp6 from '../landingpage1_6.png'

import imageLpButton1 from '../landingpage1_1_button.png'
import imageLpButton2 from '../landingpage1_2_button.png'
import imageLpButton4 from '../landingpage1_4_button.png'
import imageLpButton5 from '../landingpage1_5_button.png'

const images = [
  { src: imageLp1, buttonName: 'Solicite seu Orçamento', showButton: true, buttonPosition: 'middle', butonImage: imageLpButton1, buttonType: 'big' },
  { src: imageLp2, buttonName: 'Solicitar meu Orçamento', showButton: true, buttonPosition: 'bottom', butonImage: imageLpButton2, buttonType: 'normal' },
  { src: imageLp3, buttonName: 'Quero Tirar Dúvidas', showButton: true, buttonPosition: 'middle', butonImage: imageLpButton4, buttonType: 'normal' },
  { src: imageLp4, showButton: false, buttonPosition: '', buttonType: 'normal' },
  { src: imageLp5, showButton: false, buttonPosition: '', buttonType: 'normal' },
  { src: imageLp6, buttonName: 'Me chame no whatsapp', showButton: true, buttonPosition: 'bottom', butonImage: imageLpButton5, buttonType: 'normal' }
];

function LandingPage1() {
  return (
    <div className="image-gallery">
      {images.map((image, index) => (
        <div key={index} className="image-container">
          <img src={image.src} alt={`Gallery ${index + 1}`} />
          {image.showButton && (
            <img
             src={image.butonImage}
             className={`image-button${image.buttonType === 'big' ? 'Big' : 'Normal'} ${image.buttonPosition}`}
             onClick={() => {
              handleClick(image.buttonName);
            }}
            >
            </img>
          )}
        </div>
      ))}

      <FloatingWhatsApp
        phoneNumber='558199332898' // Required
        accountName='Dr. Cálculo Jurídico' // Optional
        avatar='../logo_blue.jpg' // Optional
        messageDelay='1'
        chatMessage='Olá! Como posso te ajudar?' // Optional
        statusMessage='Online' // Optional
        placeholder='Escreva aqui...' // Optional
        notification = 'true' 
        notificationDelay = '7'
        notificationSound = 'true' 
        allowEsc={true} // Optional
      />
    </div>
  );
}

const handleClick = (buttonName) => {

  logEvent(analytics, `LP1 buttonClicked:${buttonName}`);

  const whatsappNumber = "558199332898";

  const message = "Olá, gostaria de realizar um orçamento.";

    window.open(`https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`, '_blank');
 
};

export default LandingPage1;