import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import { Card } from "@material-tailwind/react";
import Footer from '../components/FooterBottom';
import { logEvent, analytics }  from '../firebase';


import { atualizacaoMonetariaFetchData } from '../request/AtualizacaoMonetarioRequest';
import { indicesFetchData } from '../request/IndicesRequest';

import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ReactDOM from 'react-dom';
import { PDFViewer } from '@react-pdf/renderer';
import MyDocument from '../pdf/AtualizacaoMonetariaPDF';
dayjs.locale('pt-br');

function Civel() {

  // Seus estados para os valores dos campos
  const [dataInicioIndice, setDataInicioIndice] = React.useState();
  const [dataFimIndice, setDataFimIndice] = React.useState();
  const [invalidDateIndiceInicio, setInvalidDateIndiceInicio] = React.useState(false);
  const [invalidDateIndiceFim, setInvalidDateIndiceFim] = React.useState(false);

  const [minIndiceDataInicioIndice, setMinIndiceDataInicioIndice] = React.useState();
  const [minIndiceDataFimIndice, setMinIndiceDataFimIndice] = React.useState();

  const [dataInicioJuros, setDataInicioJuros] = React.useState();
  const [dataFimJuros, setDataFimJuros] = React.useState();

  const [dataInicioMulta, setDataInicioMulta] = React.useState();
  const [dataFimMulta, setDataFimMulta] = React.useState();

  const [valor, setValor] = React.useState();
  const [indice, setindice] = React.useState('');
  const [tipoJuros, setJurosTipo] = React.useState("não aplicar");
  const [juros, setJurosPorcentagem] = React.useState();
  var [tipoMulta, setTipoMulta] = React.useState("");
  const [multa, setMultaPorcentagem] = React.useState();

  const [indiceValue, setIndiceValue] = React.useState(0);
  const [indiceAplicado, setIndiceAplicado] = React.useState(0);
  const [jurosAplicado, setJurosAplicado] = React.useState();
  const [multaAplicada, setMultaAplicada] = React.useState();
  const [valorCorrigido, setValorCorrigido] = React.useState(0);
  const [totalValor, setTotalValor] = React.useState(0);
  const [objectoToDocument, setObjectoToDocument] = React.useState([]);
  const [indicesObject, setIndicesObject] = React.useState([]);


  // Eventos de visualização
  const [dadosCarregados, setDadosCarregados] = React.useState(false);
  const [showJurosValue, setShowJurosValue] = React.useState(false);

  const [indiceValido, setindiceValido] = React.useState(false);
  const [valorValid, setValorValido] = React.useState(false);
  const [dataInicioValido, setDataInicioValido] = React.useState(false);
  const [dataFimValido, setDataFimValido] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);

  const calculateValues = async () => {

    setLoading(true)

    try {
      const requestData = {
        dataInicioIndice: formatDateToRequest(dataInicioIndice),
        dataFimIndice: formatDateToRequest(dataFimIndice),
        valor,
        indice,
        juros,
        dataInicioJuros: formatDateToRequest(dataInicioJuros),
        dataFimJuros: formatDateToRequest(dataFimJuros),
        tipoJuros,
        multa,
        tipoMulta,
        dataInicioMulta: formatDateToRequest(dataInicioMulta),
        dataFimMulta: formatDateToRequest(dataFimMulta),
      };

      const responseData = await atualizacaoMonetariaFetchData(requestData);

      const object = {
        valorResult: responseData.resultado.valor,
        startDateIndice: dataInicioIndice,
        endDateIndice: dataFimIndice,
        diasIndice: responseData.resultado.diasIndice,
        indiceResult: responseData.resultado.indice,
        indiceAplicadoResult: responseData.resultado.indiceAplicado,
        startDateJuros: dataInicioJuros,
        endDateJuros: dataFimJuros,
        diasJuros: responseData.resultado.diasJuros,
        jurosResult: responseData.resultado.juros,
        jurosAplicadoResult: responseData.resultado.jurosAplicado,
        startDateMulta: dataInicioMulta,
        endDateMulta: dataFimMulta,
        diasMulta: responseData.resultado.diasMulta,
        multaResult: responseData.resultado.multa,
        multaAplicadaResult: responseData.resultado.multaAplicada,
        valorCorrigidoResult: responseData.resultado.valorCorrigido,
        totalValorResult: responseData.resultado.totalValor
      };

      // Atualize o estado da tabela com os novos dados
      setObjectoToDocument(object)

      setIndiceValue(responseData.resultado.indice)
      setIndiceAplicado(responseData.resultado.indiceAplicado)
      setJurosAplicado(responseData.resultado.jurosAplicado)
      setMultaAplicada(responseData.resultado.multaAplicada)
      setValorCorrigido(responseData.resultado.valorCorrigido)
      setTotalValor(responseData.resultado.totalValor)

      setDadosCarregados(true)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      try {
        const responseData = await indicesFetchData();
        setIndicesObject(responseData);

        setindice(indicesObject[0].codigo)
       
        setMinIndiceDataInicioIndice(dayjs(indicesObject[0].startDate))
        setMinIndiceDataFimIndice(dayjs(indicesObject[0].endDate))

      } catch (error) {
        setLoading(false);
        console.log(error);
      }
      setLoading(false);
    };
    fetchData(); // Chama a função fetchData() uma vez ao montar o componente
  }, []); // O array vazio [] garante que o efeito seja executado apenas uma vez



  const visualizarImpressao = () => {

    logEvent(analytics, 'atualizacao_monetaria_gerar_pdf', { indice });

    const newWindow = window.open();
    ReactDOM.render(
      <div style={{ width: '100vw', height: '100vh' }}>
        <PDFViewer style={{ width: '100%', height: '100%' }} download="dr_calculo_juridico.pdf">
          <MyDocument data={objectoToDocument} />
        </PDFViewer>
      </div>,


      newWindow.document.body
    );
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      calculateValues();
    }
  };

  const handleButtonClick = () => {
    if (!loading) {
      if (invalidDateIndiceInicio || invalidDateIndiceFim) {
        alert('Por favor, ajuste as datas antes de continuar.');
      } else {
        logEvent(analytics, 'atualizacao_monetaria_executar_calculo', { indice });
        setDadosCarregados(false)
        calculateValues()
      }
    }
  };

  const handleChangeMulta = (event) => {
    setTipoMulta(event.target.value);
  };


  return (

    <div className="bg-gray-300  min-h-screen  flex flex-col">

     {/*  <div className="text-center mb-12 font-mono"  >
        <p className="font-mono text-4xl">Atualização Monetária</p> 
      </div>*/}

      <div style={{ maxWidth: '550px', margin: '0 auto' }} className="flex-col justify-center">
        
          <Card style={{ marginTop: '100px', background:'white', borderRadius: '5px', padding: '10px' }}>
            <div className="flex flex-row space-x-5 mb-1 justify-center">
              <div className="flex flex-col">
                <InputLabel style={{ width: '100px' }} id="demo-simple-select-autowidth-label">Índices</InputLabel>
                <Select style={{ width: '350px' }}
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth"
                  value={indice}
                  onChange={(i) => {
                    setindice(i.target.value)

                    var object = indicesObject.find(indice => indice.codigo === i.target.value)

                    const format = 'DD/MM/YYYY';
                    const startDate = dayjs(object.startDate, format);
                    const endDate = dayjs(object.endDate, format);

                    setMinIndiceDataInicioIndice(startDate)
                    setMinIndiceDataFimIndice(endDate)
                  }
                  }
                  label="Índice"
                >
                  {indicesObject.map(indice => (
                    <MenuItem key={indice.codigo} value={indice.codigo}>
                      {`${indice.nome} - ${indice.startDate} até ${indice.endDate}`}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div className="flex-initial mt-2">
                <InputField
                  label="Valor R$"
                  value={valor}
                  type="text"
                  onChange={(e) => {
                    const valorDigitado = e.target.value;
                    const valorFormatado = formatarValor(valorDigitado);
                    setValor(valorFormatado);
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row space-x-5 justify-center">

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Data de Início"
                  value={dataInicioIndice}
                  onChange={(newValue) => {
                    if (dayjs(newValue).isBefore(minIndiceDataInicioIndice)) {
                      setInvalidDateIndiceInicio(true)
                      return;
                    }
                    setDataInicioIndice(newValue);
                    setInvalidDateIndiceInicio(false)
                  }}
                  
                  minDate={minIndiceDataInicioIndice}
                  maxDate={minIndiceDataFimIndice}
                  format="DD/MM/YYYY"
                />
            </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Data Final"
                  value={dataFimIndice}
                  onChange={(newValue) => {

                    if (dayjs(newValue).isAfter(minIndiceDataFimIndice)) {
                      setInvalidDateIndiceFim(true)
                      return;
                    }
                    setDataFimIndice(newValue);
                    setInvalidDateIndiceFim(false)
                  }}
                  minDate={minIndiceDataInicioIndice}
                  maxDate={minIndiceDataFimIndice}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>

            </div>

          </Card>

          <Card style={{background:'white', borderRadius: '5px', padding: '10px', marginTop: '20px' }}>
            <div className="flex flex-row space-x-5 mb-1 justify-center">

              <div className="flex flex-col">
                <InputLabel id="demo-simple-select-autowidth-label">Juros</InputLabel>
                <Select style={{ width: '300px' }}
                  labelId="demo-simple-select-autowidth-label"
                  id="demo-simple-select-autowidth-label"
                  value={tipoJuros}
                  onChange={(i) => {
                    setJurosTipo(i.target.value)
                    if (i.target.value !== "simples" && i.target.value !== "composto") {
                      setShowJurosValue(false);
                    } else {
                      setShowJurosValue(true);
                    }
                    setDadosCarregados(false)
                  }
                  }
                  label="Juros">

                  <MenuItem value={"não aplicar"}>Não Aplicar</MenuItem>
                  <MenuItem value={"simples"}>Simples</MenuItem>
                  <MenuItem value={"composto"}>Composto</MenuItem>
                </Select>
              </div>

              <div className="mt-2">
                <PercentageInputField
                  label="Juros a.m."
                  value={juros}
                  onChange={(e) => {
                    setJurosPorcentagem(e.target.value)
                    setDadosCarregados(false)
                  }}
                />
              </div>
            </div>

            <div className="flex flex-row space-x-5 justify-center">

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Data de Início"
                  value={dataInicioJuros}
                  onChange={(newValue) => setDataInicioJuros(newValue)}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Data Final"
                  value={dataFimJuros}
                  onChange={(newValue) => setDataFimJuros(newValue)}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>

            </div>

          </Card>

          <Card style={{background:'white', borderRadius: '5px', padding: '10px', marginTop: '20px' }}>
            <InputLabel id="demo-simple-select-autowidth-label">Multa</InputLabel>

            <div className="flex flex-row space-x-5 mb-1 justify-center">

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={
                    tipoMulta
                  }
                  onChange={
                    handleChangeMulta
                  }
                >
                  <div className="flex-col">

                    <FormControlLabel
                      value=""
                      control={<Radio sx={{
                        color: 'rgb(12, 44, 69)',
                        '&.Mui-checked': {
                          color: 'rgb(12, 44, 69)',
                        },
                      }} />}
                      label="Não Aplicar"
                      checked={tipoMulta === ""} />

                    <FormControlLabel
                      value="unico"
                      control={<Radio sx={{
                        color: 'rgb(12, 44, 69)',
                        '&.Mui-checked': {
                          color: 'rgb(12, 44, 69)',
                        },
                      }} />}
                      label="Aplicação Única"
                      checked={tipoMulta === "unico"} />

                    <FormControlLabel
                      value="mensal"
                      control={<Radio sx={{
                        color: 'rgb(12, 44, 69)',
                        '&.Mui-checked': {
                          color: 'rgb(12, 44, 69)',
                        },
                      }} />}
                      label="Aplicação Mensal"
                      checked={tipoMulta === "mensal"} />
                  </div>
                </RadioGroup>
              </div>

              <PercentageInputField
                label="Multa"
                value={multa}
                onChange={(e) => {
                  setMultaPorcentagem(e.target.value)
                  setDadosCarregados(false)
                }}
              />
            </div>

            <div className="flex flex-row space-x-5   justify-center">

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Data de Início"
                  value={dataInicioMulta}
                  onChange={(newValue) => setDataInicioMulta(newValue)}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Data Final"
                  value={dataFimMulta}
                  onChange={(newValue) => setDataFimMulta(newValue)}
                  format="DD/MM/YYYY"
                />
              </LocalizationProvider>

            </div>
          </Card>
        
        <Box sx={{ m: 2, position: 'relative' }}>
          <Button
            variant="contained"
            sx={{ marginTop: '200px', margin: 'auto', display: 'block',
             backgroundColor: 'rgb(12, 44, 69)',
            '&:hover': {
              backgroundColor: 'rgb(8, 36, 57)', },
              textTransform: 'capitalize'}}
            disabled={loading}
            onClick={handleButtonClick}>
            Executar Cálculo
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>

      </div>


      <div className="p-5 mt-5 flex-col justify-center" style={{ maxWidth: '550px', margin: '0 auto', display: dadosCarregados ? 'block' : 'none', backgroundColor: 'rgb(35, 60, 86)', borderRadius: '5px' }}>

        <div className="mt-1">
          <Button 
          sx={{ margin: 'auto', display: 'block' }} 
          onClick={visualizarImpressao} 
          variant="contained">Gerar PDF</Button>
        </div>

        <Box className="mt-5" sx={{ display: 'flex', marginTop: '50px' }}>
          <TableContainer component={Paper}  >
            <Table sx={{ maxWidth: 700 }} size="small" aria-label="a dense table">
              <TableBody>
                {valor !== 0 && valor !== null && valor !== undefined && (
                  <>
                    <TableRow>
                      <TableCell align="left"><strong>Valor:</strong></TableCell>
                      <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(objectoToDocument.valorResult)}</TableCell>
                    </TableRow>
                  </>
                )}
                <TableRow>
                  <TableCell align="left"><strong>Data Índice:</strong></TableCell>
                  <TableCell align="center"><strong>{formatDate(dataInicioIndice)}</strong> <strong> até</strong> <strong>{formatDate(dataFimIndice)}</strong> ({objectoToDocument.diasIndice} dias) </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell align="left"><strong>Índice:</strong></TableCell>
                  <TableCell align="center">
                    {indiceValue !== null && indiceValue !== undefined ? String(Number(indiceValue).toFixed(6)).replace(".", ",") : "N/A"}
                  </TableCell>
                </TableRow>

                {indiceAplicado !== 0 && valorCorrigido !== 0 && valor !== 0 && valor !== undefined && (
                  <>
                    <TableRow>
                      <TableCell align="left"><strong>Índice aplicado:</strong></TableCell>
                      <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(indiceAplicado)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left"><strong>Valor corrigido:</strong></TableCell>
                      <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valorCorrigido)}</TableCell>
                    </TableRow>
                  </>
                )}
                {juros !== 0 && tipoJuros !== "" && tipoJuros !== "não aplicar" && juros !== null && juros !== undefined && (
                  <>
                    <TableRow>
                      <TableCell align="left"><strong>Data Juros:</strong></TableCell>
                      <TableCell align="center"><strong>{formatDate(dataInicioJuros)}</strong> <strong>até</strong> <strong>{formatDate(dataFimJuros)}</strong> ({objectoToDocument.diasJuros} dias) </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left"><strong>Juros:</strong></TableCell>
                      <TableCell align="center">{String(juros).replace(".", ",") + "%"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left"><strong>Juros aplicado:</strong></TableCell>
                      <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(objectoToDocument.jurosAplicadoResult)}</TableCell>
                    </TableRow>
                  </>
                )}

                {multa !== 0 && multa !== null && tipoMulta !== null && multa !== undefined && tipoMulta == 'mensal' && (
                  <>
                    <TableRow>
                      <TableCell align="left"><strong>Data Multa:</strong></TableCell>
                      <TableCell align="center"><strong>{formatDate(dataInicioMulta)}</strong> <strong>até</strong> <strong>{formatDate(dataFimMulta)}</strong> ({objectoToDocument.diasMulta} dias) </TableCell>
                    </TableRow>
                  </>
                )}


                {multa !== 0 && multa !== null && tipoMulta !== null && multa !== undefined && tipoMulta !== "" && (
                  <>
                    <TableRow>
                      <TableCell align="left"><strong>Multa:</strong></TableCell>
                      <TableCell align="center">{String(objectoToDocument.multaResult).replace(".", ",") + "%"}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left"><strong>Multa Aplicada:</strong></TableCell>
                      <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(objectoToDocument.multaAplicadaResult)}</TableCell>
                    </TableRow>
                  </>
                )}
                {valor !== 0 && valor !== null && valor !== undefined && (
                  <>
                    <TableRow>
                      <TableCell align="left"><strong>Total:</strong></TableCell>
                      <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(Number(objectoToDocument.totalValorResult))}</TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

      </div>

      <Footer />
    </div >
  );
};

export default Civel;


const InputField = ({ label, value, type, onChange }) => {
  return (
    <TextField
      label={label}
      type={type}
      value={value}
      onChange={onChange}
      fullWidth
      margin="normal"
    />
  );
};

const PercentageInputField = ({ label, value, onChange }) => {
  return (
    <InputField
      label={`${label} (%)`}
      type="number"
      value={value}
      onChange={onChange}
    />
  );
};

function formatDate(dataParam) {

  const data = new Date(dataParam);

  const dia = data.getDate().toString().padStart(2, '0');
  const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // Mês é base 0, então precisamos adicionar 1
  const ano = data.getFullYear();
  return `${dia}/${mes}/${ano}`;
}


function formatDateMaxDate(dataParam) {

  const data = new Date(dataParam);

  const dia = data.getDate().toString().padStart(2, '0');
  const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // Mês é base 0, então precisamos adicionar 1
  const ano = data.getFullYear();
  return `${dia}/${mes}/${ano}`;
}

function formatDateToRequest(dataParam) {

  const data = new Date(dataParam);

  const dia = data.getDate().toString().padStart(2, '0');
  const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // Mês é base 0, então precisamos adicionar 1
  const ano = data.getFullYear();
  return `${ano}-${mes}-${dia}`;
}


function formatarValor(valor) {
  // Remove todos os caracteres que não sejam números
  const numeroLimpo = valor.replace(/\D/g, '');

  // Formata o número para ter duas casas decimais
  const numeroFormatado = (Number(numeroLimpo) / 100).toLocaleString('pt-BR', { minimumFractionDigits: 2 });

  return numeroFormatado;
}

function formatarRealParaNumero(valorReal) {
  // Remove todos os caracteres que não sejam números ou ponto
  const numeroLimpo = valorReal.replace(/[^\d.,]/g, '');

  // Substitui a vírgula por ponto para permitir a conversão para número
  const numeroComPonto = numeroLimpo.replace(',', '.');

  // Converte para número
  const numero = parseFloat(numeroComPonto);

  // Retorna o número
  return numero;
}