import React from 'react';

function Profile() {

  return (

<div style={{ textAlign: 'center', margin: '50px' }}>
<h1>Perfil em desenvolvimento</h1>
</div>

);
};

export default Profile;