import React from 'react';
import MenuLateral from '../components/MenuNew';
import Content from '../components/ContentHome';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import "../css/Main.css"




function App() {
  const [selectedTab, setSelectedTab] = React.useState('Home');
  const [menuVisible, setMenuVisible] = React.useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <div className="app-container">
      <button onClick={toggleMenu} className="hamburger-btn">
        <div></div>
        <div></div>
        <div></div>
      </button>
      <div className="main-layout">
        <div className={`menu-lateral ${menuVisible ? 'show' : ''}`}>
          <MenuLateral selectedTab={selectedTab} setSelectedTab={setSelectedTab} setMenuVisible={setMenuVisible} />
        </div>
        <div className="content-area" onClick={() => menuVisible && toggleMenu()}>
          <Content selectedTab={selectedTab} />
        </div>

      </div>
      
      <FloatingWhatsApp
        phoneNumber='558199332898' // Required
        accountName='Dr. Cálculo Jurídico' // Optional
        avatar='../logo192.png' // Optional
        messageDelay='1'
        chatMessage='Olá! Como posso te ajudar?' // Optional
        statusMessage='Online' // Optional
        placeholder='Escreva aqui...' // Optional
        notification = 'true' 
        notificationDelay = '7'
        notificationSound = 'true' 
        allowEsc={true} // Optional
      />
    </div>
  );
}

export default App;

/* 


 <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: "rgb(35, 60, 86)"}}>
<div className="bg-slate-100" style={{ display: 'flex', flexDirection: 'column', minWidth: '900px', height: '100vh' }}>
  <div style={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
    <div style={{ flexShrink: 0, height: '100%', overflow: 'hidden' }}>
      <MenuLateral selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
    </div>
    <div style={{  overflowY: 'auto' }}>
      <Content selectedTab={selectedTab} />
    </div>
  </div>
  
</div>

  return (

    <div style={{ textAlign: 'center', margin: '50px', marginLeft:'240px' }}>
      <button onClick={toggleMenu}></button>
      <MenuLateral isOpen={menuOpen} onClose={toggleMenu} selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      
      <div style={{ marginLeft: menuOpen ? 640 : 0, transition: 'margin 0.5s' }}>
      
        <div className="App">
          <Content selectedTab={selectedTab} />
        </div>

      </div>
    </div>
  );   */
  

  /* 
    <div>
    <div style={{ display: 'flex' }}>
      <MenuLateral selectedTab={selectedTab} setSelectedTab={setSelectedTab}></MenuLateral>
      <Content style={{ marginLeft:'240px'}} selectedTab={selectedTab} /> 
         </div>

         <Footer></Footer>
         </div> */