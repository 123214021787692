import React from "react";
import PropTypes from 'prop-types';
import { Card, Typography } from "@material-tailwind/react";
import Footer from '../components/FooterBottom';


const newsData = [
  {
    title: "PJe-Calc",
    date: "20/01/2024",
    image: "https://www.trt8.jus.br/sites/portal/files/roles/pje-calc/banner-pjecalc-v2.jpg",
    description: "O PJe-Calc é o Sistema de Cálculo Trabalhista desenvolvido pela Secretaria de Tecnologia da Informação do Tribunal Regional do Trabalho da 8ª Região, a pedido do Conselho Superior da Justiça do Trabalho, para utilização em toda a Justiça do Trabalho como ferramenta padrão de elaboração de cálculos trabalhistas e liquidação de sentenças, visando a uniformidade de procedimentos e confiabilidade nos resultados apurados, sendo indicado pela Recomendação CGJT nº 4/2018...",
    link: "https://www.trt8.jus.br/pjecalc-cidadao"
  },
  {
    title: "STF valida atualização dos juros moratórios mesmo em condenações definitivas contra a Fazenda Pública",
    date: "12/12/2023",
    image: "https://www.stf.jus.br/arquivo/cms/bancoImagemSco/bancoImagemSco_AP_501244.jpg",
    description: "O Plenário do Supremo Tribunal Federal (STF) decidiu que o trânsito em julgado (fase processual em que não cabe mais recurso) em condenações contra a Fazenda Pública não impede a atualização do índice de correção moratória de dívidas não tributárias pelo índice legal. A decisão, unânime, foi tomada no julgamento do Recurso Extraordinário (RE) 1317982, com repercussão geral (Tema 1.170), na sessão virtual concluída em 11/12...",
    link: "https://portal.stf.jus.br/noticias/verNoticiaDetalhe.asp?idConteudo=523404&ori=1"
  },
  {
    title: "Justiça determina pensão por morte a menor que vivia sob guarda da avó",
    date: "18/06/2024",
    image: "https://previdenciarista.com/_next/image/?url=https%3A%2F%2Fd2ws28wh88s8ta.cloudfront.net%2Fblog%2Fwp-content%2Fuploads%2F2024%2F06%2Fpensao-por-morte-avo-iStock-137063152-18-06-2024-scaled.jpg.webp&w=1920&q=75",
    description: "Um menor de idade que vivia sob a guarda de sua avó, servidora pública, passou a receber pensão por morte, benefício reconhecido pela 1ª Turma do Tribunal Regional Federal da 1ª Região (TRF1). Na 1ª instância, o pedido do autor foi julgado improcedente pelo Juízo Federal da 2ª Vara da Seção Judiciária de Roraima (SJRR) por ausência de comprovação de dependência econômica da servidora...",
    link: "https://previdenciarista.com/blog/justica-determina-pensao-por-morte-a-menor-que-vivia-sob-guarda-da-avo/"
  },
  {
    title: "Comissão aprova prazo para revisão de aposentadoria por incapacidade",
    date: "11/06/2024",
    image: "https://previdenciarista.com/_next/image/?url=https%3A%2F%2Fd2ws28wh88s8ta.cloudfront.net%2Fblog%2Fwp-content%2Fuploads%2F2024%2F05%2Fcongresso-nacional-iStock-1133543927-14-05-2024-scaled.jpg.webp&w=1920&q=75",
    description: "O Projeto de Lei (PL) 5609/23, que unifica em cinco anos o prazo para o poder público revisar a concessão de aposentadoria por incapacidade de servidores públicos federais e de segurados do Instituto Nacional do Seguro Social (INSS), foi aprovado pela Comissão de Segurança Pública da Câmara dos Deputados. A ementa também impacta militares das Forças Armadas e do Distrito Federal...",
    link: "https://previdenciarista.com/blog/comissao-aprova-prazo-para-revisao-de-aposentadoria-por-incapacidade/"
  },
  {
    title: "STF decide que remuneração do FGTS não pode ser inferior ao IPCA",
    date: "12/06/2024",
    image: "https://images.jota.info/wp-content/uploads/2024/06/stf-plenario-1024x683.jpg",
    description: "Pela técnica do voto-médio, o Supremo Tribunal Federal (STF) decidiu nesta quarta-feira (12/6) que deve ser mantido o modelo atual de correção do FGTS (Taxa Referencial mais juros de 3% ao ano, além da distribuição de lucros), mas também deve ser garantido, no mínimo, a correção dos valores pelo IPCA...",
    link: "https://www.jota.info/stf/do-supremo/stf-decide-que-remuneracao-do-fgts-nao-pode-ser-inferior-a-do-ipca-12062024?non-beta=1"
  }
];


function News() {
  return (
    <div className="p-6 bg-gray-300 min-h-screen flex flex-col">
      <Typography variant="h4" className="mb-6 text-center">
        Últimas Notícias Jurídicas
      </Typography>
      <div className="flex flex-col items-center space-y-6 cen">
        {newsData.map((news, index) => (
          <Card 
            key={index} 
            style={{maxWidth:'1000px', width: '100%' }}
            className="p-6 bg-white shadow-md cursor-pointer" 
            onClick={() => window.open(news.link, '_blank')}
          >
            <div className="flex justify-between items-center mb-4">
              <Typography variant="h5" className="font-bold">
                {news.title}
              </Typography>
              <Typography variant="small" className="text-gray-500">
                {news.date}
              </Typography>
            </div>
            <div className="mb-4">
              <img src={news.image} alt={news.title} className="w-full h-48 object-cover" />
            </div>
            <Typography variant="paragraph">
              {news.description}
            </Typography>
            {index < newsData.length - 1 && <hr className="my-6" />}
          </Card>
        ))}
      </div>
      <Footer/>

    </div>
  );
}

export default News;