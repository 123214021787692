import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import { civelFetchData } from '../request/calculationCivel';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Impressao } from '../pdf/AtualizacaoMonetariaPDF';
import { color } from 'framer-motion';

pdfMake.vfs = pdfFonts.pdfMake.vfs;


function Civel() {

  // Seus estados para os valores dos campos
  const [dataInicio, setDataInicio] = React.useState('2022-05-20');
  const [dataFim, setDataFim] = React.useState('2023-03-15');
  const [valor, setValor] = React.useState(1000);
  const [indice, setindice] = React.useState(188);
  const [multa, setMultaPorcentagem] = React.useState(0);
  const [tipoJuros, setJurosTipo] = React.useState('Simples');
  const [juros, setJurosPorcentagem] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [totalValue, setTotalValue] = React.useState(0);
  const [totalIndice, setTotalIndice] = React.useState(0);
  const [totalValoresIndice, setTotalValoresIndice] = React.useState(0);
  const [totalValoresAtualizados, setTotalValoresAtualizados] = React.useState(0);
  var objectoToDocument = [];

  // Eventos de visualização
  const [dadosCarregados, setDadosCarregados] = React.useState(false);
  const [showJurosValue, setShowJurosValue] = React.useState(false);
  const [columNumTable, setColumNumTable] = React.useState(5);


  const [indiceValido, setindiceValido] = React.useState(false);
  const [valorValid, setValorValido] = React.useState(false);
  const [dataInicioValido, setDataInicioValido] = React.useState(false);
  const [dataFimValido, setDataFimValido] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const calculateValues = async () => {

    setLoading(true)

    try {
      // Obtenha os dados necessários do estado para enviar na requisição
      const requestData = {
        dataInicio,
        dataFim,
        valor,
        indice,
        tipoJuros,
        juros,
        multa,
      };

      // Faça a requisição
      const responseData = await civelFetchData(requestData);

      // Atualize o estado da tabela com os dados da resposta
      const lista = responseData.resultado.data.map(item =>
        createData(
          item.competencia,
          item.diasMes,
          item.valorMes,
          item.indice,
          item.indiceValor,
          item.juros,
          item.jurosValor,
          item.multa,
          item.multaValor,
          item.valorAtualizado
        )
      );

      const totalValores = responseData.resultado.totalValores;
      const totalIndice = responseData.resultado.totalIndice;
      const totalValoresIndice = responseData.resultado.totalValoresIndice;
      const totalValoresAtualizados = responseData.resultado.totalValoresAtualizados;

      const object = {
        listaObjetos: lista,
        totalValores: totalValores,
        totalIndice: totalIndice,
        totalValoresIndice: totalValoresIndice,
        totalValoresAtualizados: totalValoresAtualizados
      };

      var columNumTableInit = 5

      if (lista && lista.length > 0) {
        if (lista[0].juros > 0) {
          columNumTableInit = columNumTableInit + 2
          setColumNumTable(columNumTableInit)
        }

        if (lista[0].multa > 0) {
          columNumTableInit = columNumTableInit + 2
          setColumNumTable(columNumTableInit)
        }
      }

      // Atualize o estado da tabela com os novos dados
      objectoToDocument = object
      setRows(lista);
      setTotalValue(totalValores)
      setTotalIndice(totalIndice)
      setTotalValoresIndice(totalValoresIndice)
      setTotalValoresAtualizados(totalValoresAtualizados)

      setDadosCarregados(true)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  };


  const gerarPDF = () => {
    //const classeImpressao = new Impressao(objectoToDocument);
    // const documento = classeImpressao.GerarDocumento();
    // pdfMake.createPdf(documento).open({}, window.open('', '_blank'));
  }

  // Função para criar um objeto de dados no formato desejado
  function createData(competencia, diasMes, valorMes, indice, indiceValor, juros, jurosValor, multa, multaValor, valorAtualizado) {
    return { competencia, diasMes, valorMes, indice, indiceValor, juros, jurosValor, multa, multaValor, valorAtualizado };
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      calculateValues();
    }
  };

  const handleButtonClick = () => {
    if (!loading) {
      calculateValues()
    }
  };


const validateFields = () => {

  if(valor == null || valor <=0) {
    

  }
}



  return (

    <div style={{ margin: '50px auto' }}>

      <div className="text-center mb-12 font-mono"  >
        <p className="font-mono text-4xl">Atualização Monetária</p>
      </div>

      <div style={{ maxWidth: '550px', margin: '0 auto' }} className="flex-col justify-center">

        <Box>
          <div className="flex flex-row space-x-5 mb-5 justify-center">
            <div className="flex flex-col">
              <InputLabel style={{ width: '100px' }} id="demo-simple-select-autowidth-label">Índice</InputLabel>
              <Select style={{ width: '300px' }}
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={indice}
                onChange={(i) => setindice(i.target.value)}
                label="Índice"
              >

                <MenuItem value={28655}>IGP-M (FGV)</MenuItem>
                <MenuItem value={190}>IGP-DI (FGV)</MenuItem>
                <MenuItem value={188}>INPC (IBGE)</MenuItem>
                <MenuItem value={433}>IPCA (IBGE)</MenuItem>
                <MenuItem value={10764}>IPCA-E (IBGE)</MenuItem>
                <MenuItem value={191}>IPC-BRASIL (FGV)</MenuItem>
                <MenuItem value={193}>IPC-SP (FIPE)</MenuItem>
              </Select>
            </div>
            <div className="flex-initial mt-2">
              <InputField
                label="Valor"
                value={valor}
                type="number"
                onChange={(e) => setValor(e.target.value)}
              />
            </div>
          </div>
        </Box>

        <Box>
          <div className="flex flex-row space-x-5 mb-5  justify-center">

            <InputField
              label="Data de Início"
              value={dataInicio}
              type="date"
              onChange={(e) => setDataInicio(e.target.value)}
            />
            <InputField
              label="Data Final"
              value={dataFim}
              type="date"
              onChange={(e) => setDataFim(e.target.value)}
            />

          </div>
        </Box>


        <Box>
          <div className="flex flex-row space-x-5 mb-5 justify-center">

            <div className="flex flex-col">
              <InputLabel id="demo-simple-select-autowidth-label">Juros</InputLabel>
              <Select style={{ width: '300px' }}
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth-label"
                value={tipoJuros}
                onChange={(i) => {
                  setJurosTipo(i.target.value)
                  if (i.target.value !== "simples" && i.target.value !== "composto") {
                    setShowJurosValue(false);
                  } else {
                    setShowJurosValue(true);
                  }
                }
                }
                label="Juros">

                <MenuItem value={""}>Não Aplicar</MenuItem>
                <MenuItem value={"simples"}>Simples</MenuItem>
                <MenuItem value={"composto"}>Composto</MenuItem>
              </Select>
            </div>

            <div className="mt-2" style={{ display: showJurosValue ? 'block' : 'none' }}>
              <PercentageInputField
                label="Juros a.m."
                value={juros}
                onChange={(e) => setJurosPorcentagem(e.target.value)}
              />
            </div>
          </div>


          <div className="flex flex-row space-x-5 mb-5 justify-center">

            <PercentageInputField
              label="Multa"
              value={multa}
              onChange={(e) => setMultaPorcentagem(e.target.value)}
            />
          </div>
        </Box>

        <Box sx={{ m: 1, position: 'relative' }}>
          <Button
            variant="contained"
            sx={{ marginTop: '200px', margin: 'auto', display: 'block' }}
            disabled={loading}
            onClick={handleButtonClick}>
            Executar Cálculo
          </Button>
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: color.blue,
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px',
              }}
            />
          )}
        </Box>










      </div>


      <div className="bg-slate-200 p-5 mt-5" style={{ display: dadosCarregados ? 'block' : 'none' }}>

        <div className="mt-1">
          <Button
           sx={{ margin: 'auto', display: 'block', color: color.blue }}
            onClick={gerarPDF()} variant="contained">
              Gerar PDF
              </Button>
        </div>

        <Box className="mt-5" sx={{ display: 'flex', marginTop: '50px' }}>
          <TableContainer component={Paper}  >
            <Table sx={{ maxWidth: 700 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Competência</TableCell>
                  <TableCell align="center">Dias</TableCell>
                  <TableCell align="center">Valor</TableCell>
                  <TableCell align="center">Índice</TableCell>
                  <TableCell align="center">Índice Aplicado</TableCell>

                  {rows.length > 0 && rows[0].juros !== 0 && rows[0].juros > 0 && (
                    <>
                      <TableCell align="center">Juros</TableCell>
                      <TableCell align="center">Juros Aplicado</TableCell>
                    </>
                  )}

                  {rows.length > 0 && rows[0].multa !== 0 && rows[0].multa > 0 && (
                    <>
                      <TableCell align="center">Multa</TableCell>
                      <TableCell align="center">Multa Aplicada</TableCell>
                    </>
                  )}

                  <TableCell align="center">Valor Atualizado</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.competencia} sx={{ '&:last-child td, &:last-child th': { border: 5 } }}>
                    <TableCell align="center">{row.competencia}</TableCell>
                    <TableCell align="center">{row.diasMes}</TableCell>
                    <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.valorMes)}</TableCell>
                    <TableCell align="center">{String(row.indice).replace(".", ",") + "%"}</TableCell>
                    <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.indiceValor)}</TableCell>
                    {row.juros !== 0 && row.juros > 0 && (
                      <>
                        <TableCell align="center">{String(row.juros).replace(".", ",") + "%"}</TableCell>
                        <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.jurosValor)}</TableCell>
                      </>
                    )}

                    {row.multa !== 0 && row.multa > 0 && (
                      <>
                        <TableCell align="center">{String(row.multa).replace(".", ",") + "%"}</TableCell>
                        <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.multaValor)}</TableCell>
                      </>
                    )}

                    <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(row.valorAtualizado)}</TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell colSpan={columNumTable} align="left"><strong>Total valor:</strong></TableCell>
                  <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValue)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={columNumTable} align="left"><strong>Total Indice:</strong></TableCell>
                  <TableCell align="center">{String(totalIndice).replace(".", ",") + "%"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={columNumTable} align="left"><strong>Total Indice Valor:</strong></TableCell>
                  <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValoresIndice)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={columNumTable} align="left"><strong>Total:</strong></TableCell>
                  <TableCell align="center">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalValoresAtualizados)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>

      </div>
    </div >
  );
};

export default Civel;


const InputField = ({ label, value, type, onChange }) => {
  return (
    <TextField
      label={label}
      type={type}
      value={value}
      onChange={onChange}
      fullWidth
      margin="normal"
    />
  );
};

const PercentageInputField = ({ label, value, onChange }) => {
  return (
    <InputField
      label={`${label} (%)`}
      type="number"
      value={value}
      onChange={onChange}
    />
  );
};