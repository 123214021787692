import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';

// Definir estilos para o PDF
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    fontFamily: 'Roboto',
    padding: 20,
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
  },
  subTitle: {
    fontSize: 14,
    marginBottom: 30,
    textAlign: 'center',
  },
  image: {
    marginTop: 50,
    width: '10%',
    height: 'auto',
  },
  text: {
    fontSize: 12,
    marginHorizontal: 20,
    marginBottom: 10,
  },
  textTable: {
    fontSize: 12,
    marginHorizontal: 10
  },
  table: {
    width: '80%',
    border: '1px solid #000',
    marginTop: 20,
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: '1px solid #000',
  },
  tableCell: {
    width: '90%',
    padding: 2,
  },
});

// Definir fonte Roboto
Font.register({
  family: 'Roboto',
  src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmSU5fCRc4AMP6lbBP.woff2',
});

/* valorResult:
        valorResult: responseData.resultado.valor,
        diasIndice: responseData.resultado.diasIndice,
        indiceResult: responseData.resultado.indice,
        indiceAplicadoResult: responseData.resultado.indiceAplicado,
        diasJuros: responseData.resultado.diasJuros,
        jurosResult: responseData.resultado.juros,
        jurosAplicadoResult: responseData.resultado.jurosAplicado,
        multaResult: responseData.resultado.multa,
        multaAplicadaResult: responseData.resultado.multaAplicada,
        valorCorrigidoResult: responseData.resultado.valorCorrigido,
        totalValorResult: responseData.resultado.totalValor */


        const MyDocument = ({ data }) => {
          const showJuros = data.startDateJuros && !isNaN(data.jurosResult) && !isNaN(data.jurosAplicadoResult);
          const showMulta = data.startDateMulta && !isNaN(data.multaResult) && !isNaN(data.multaAplicadaResult);
        
          return (
            <Document>
              <Page size="A4" style={styles.page}>
                {/* Título */}
                <Image style={styles.image} src="/logo192.png"></Image>
                <Text style={styles.title}>DR CÁLCULO</Text>
                <Text style={styles.subTitle}>Jurídico</Text>
        
                {/* Texto explicativo */}
                <Text style={styles.text}>
                  Este documento foi gerado pelo site DR. Cálculo Jurídico. Ele contém informações importantes sobre o cálculo realizado.
                </Text>
        
                {/* Tabela */}
                <View style={styles.table}>
        
                  {/* valor */}
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell}>
                      <Text style={styles.textTable}>Valor:</Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text style={styles.textTable}>R$ {data.valorResult}</Text>
                    </View>
                  </View>
        
                  {/* data indice */}
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell}>
                      <Text style={styles.textTable}>Data Índice:</Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text style={styles.textTable}>{formatarData(data.startDateIndice)} até {formatarData(data.endDateIndice)} ({data.diasIndice} dias)</Text>
                    </View>
                  </View>
        
                  {/* indice */}
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell}>
                      <Text style={styles.textTable}>Índice:</Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text style={styles.textTable}>{String(data.indiceResult.toFixed(6)).replace(".", ",")}</Text>
                    </View>
                  </View>
        
                  {/* indice aplicado*/}
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell}>
                      <Text style={styles.textTable}>Índice Aplicado:</Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text style={styles.textTable}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.indiceAplicadoResult)}</Text>
                    </View>
                  </View>
        
                  {/* valor corrigido */}
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell}>
                      <Text style={styles.textTable}>Valor Corrigido:</Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text style={styles.textTable}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.valorCorrigidoResult)}</Text>
                    </View>
                  </View>
        
                  {/* Exibir campos de juros apenas se os dados existirem */}
                  {showJuros && (
                    <>
                      <View style={styles.tableRow}>
                        <View style={styles.tableCell}>
                          <Text style={styles.textTable}>Data Juros:</Text>
                        </View>
                        <View style={styles.tableCell}>
                          <Text style={styles.textTable}>{formatarData(data.startDateJuros)} até {formatarData(data.endDateJuros)} ({data.diasJuros} dias)</Text>
                        </View>
                      </View>
        
                      <View style={styles.tableRow}>
                        <View style={styles.tableCell}>
                          <Text style={styles.textTable}>Juros:</Text>
                        </View>
                        <View style={styles.tableCell}>
                          <Text style={styles.textTable}>{data.jurosResult}</Text>
                        </View>
                      </View>
        
                      <View style={styles.tableRow}>
                        <View style={styles.tableCell}>
                          <Text style={styles.textTable}>Juros Aplicado:</Text>
                        </View>
                        <View style={styles.tableCell}>
                          <Text style={styles.textTable}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.jurosAplicadoResult)}</Text>
                        </View>
                      </View>
                    </>
                  )}
        
                  {/* Exibir campos de multa apenas se os dados existirem */}
                  {showMulta && (
                    <>
                      <View style={styles.tableRow}>
                        <View style={styles.tableCell}>
                          <Text style={styles.textTable}>Data Multa:</Text>
                        </View>
                        <View style={styles.tableCell}>
                          <Text style={styles.textTable}>{formatarData(data.startDateMulta)} até {formatarData(data.endDateMulta)} ({data.diasMulta} dias)</Text>
                        </View>
                      </View>
        
                      <View style={styles.tableRow}>
                        <View style={styles.tableCell}>
                          <Text style={styles.textTable}>Multa:</Text>
                        </View>
                        <View style={styles.tableCell}>
                          <Text style={styles.textTable}>{data.multaResult}</Text>
                        </View>
                      </View>
        
                      <View style={styles.tableRow}>
                        <View style={styles.tableCell}>
                          <Text style={styles.textTable}>Multa Aplicada:</Text>
                        </View>
                        <View style={styles.tableCell}>
                          <Text style={styles.textTable}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.multaAplicadaResult)}</Text>
                        </View>
                      </View>
                    </>
                  )}
        
                  {/* total */}
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell}>
                      <Text style={styles.textTable}>Total:</Text>
                    </View>
                    <View style={styles.tableCell}>
                      <Text style={styles.textTable}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.totalValorResult)}</Text>
                    </View>
                  </View>
        
                </View>
              </Page>
            </Document>
          );
        };
        
        export default MyDocument;



function formatarData(dataParam) {

  const data = new Date(dataParam);

  const dia = data.getDate().toString().padStart(2, '0');
  const mes = (data.getMonth() + 1).toString().padStart(2, '0'); // Mês é base 0, então precisamos adicionar 1
  const ano = data.getFullYear();
  return `${dia}/${mes}/${ano}`;
}