import React from 'react';
import Home from '../pages/Home';
import AtualizacaoMonetaria from '../pages/AtualizacaoMonetaria';
import News from '../pages/News';
import Civel from '../pages/Civel';
import Trabalhista from '../pages/Trabalhista';
import Previdenciario from '../pages/Precidenciario';
import Profile from '../pages/Profile';
import Settings from '../pages/Settings';

const ContentHome = ({ selectedTab }) => {
  switch (selectedTab) {
    case 'Home':
      return <Home />;
    case 'Atualização Monetária':
      return <AtualizacaoMonetaria />;
    case 'News':
      return <News />;
    case 'Cível':
      return <Civel />;
    case 'Trabalhista':
      return <Trabalhista />;
    case 'Previdenciário':
      return <Previdenciario />;
    case 'Perfil':
      return <Profile />;
    case 'Configurações':
      return <Settings />;
    default:
      return <div></div>;
  }
};

export default ContentHome;
