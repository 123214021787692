
import { Box } from '@mui/material'
import { Typography, Button } from '@material-tailwind/react'
import { FaWhatsapp } from 'react-icons/fa';
import Footer from '../components/FooterBottom';
import React, {useEffect, useState } from 'react';
import { SlArrowDown, SlArrowRight } from "react-icons/sl";
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import backgroundImageHeaderOp2 from '../HomeHeaderOp22Size.webp'
import backgroundImageWhite from '../backgroundWhite.webp'
import backgroundImageBlue from '../backgroundBluee.webp'
import '../css/HomeTextScroll.css';
import '../css/HomeQuestions.css';
import '../css/HomeHeader.css';
import '../css/Home.css';
import { logEvent, analytics }  from '../firebase';


const handleWhatsAppClick = () => {
  const whatsappNumber = "558199332898";
  const message = "Olá, gostaria de realizar um orçamento.";
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const url = isMobile 
    ? `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`
    : `https://web.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(message)}`;

  // Abre o link em uma nova aba
  window.open(url, '_blank');
};

function Home() {

  return (

    <div className="min-h-screen">
      <div className="boxHeader" style={{ 
        background: `url(${backgroundImageHeaderOp2})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        width: '100%', 
        height: '600px'  }}>
        
      <HeaderHome/>
        
        </div>


        <div className="boxSlider" style={{ 
        background: `url(${backgroundImageBlue})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        width: '100%',
        height: '700px'  }}>
      
        <HomeTextScroll/>

          </div>


      <div className="boxLight" style={{ 
        background: `url(${backgroundImageWhite})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        width: '100%',
        height:'600px'  }}>
        
      <Questions/>

      </div>


      <div className="boxDarker" style={{ 
        background: `url(${backgroundImageBlue})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        justifyContent: 'center', 
        width: '100%',
        height:'630px'  }}>
        
      <ContactHome/>
      </div>   

    </div>
  );
}


export default Home;



const HeaderHome = () => {

  return (
    <div className="text-center" style={{ height: '50vh', padding: '50px' }}>
      <Typography variant="h3" className="font-bold">
        DR CÁLCULO JURÍDICO
      </Typography>
      <Typography className="mb-9" variant="paragraph">
        O site especializado em cálculos jurídicos.
      </Typography>

      <div className="text-left" style={{ marginTop: '100px', maxWidth:'500px' }}>
        <header>
          <Typography variant="h4" sx={{ fontSize: '2.5rem', fontWeight: 'bold'}}>
            Precisão e Justiça em cada Cálculo:
          </Typography>
          <Typography variant="h4" sx={{ fontSize: '2.5rem', fontWeight: 'bold'}}>
            Solução Definitiva para Processos Judiciais
          </Typography>
          <Typography variant="h5" sx={{ fontSize: '1.5rem', marginTop: '10px' }}>
            Equipe de Peritos Especializados
          </Typography>
          <Typography variant="h5" sx={{ fontSize: '1.5rem', marginTop: '10px' }}>
          + 5.000 cálculos realizados no ano de 2023
          </Typography>
          <Box sx={{ marginTop: '100px', textAlign: 'center' }}>


          </Box>
        </header>
      </div>


      <Button
              size="lg"
              variant="filled"
              className="flex items-center justify-center mx-auto mb-5"
              sx={{
                marginRight: '10px',
                display: 'flex',
                alignItems: 'center'
              }}
              onClick={() => {
                logEvent(analytics, 'whatsapp(Header)'); // Adicionar o evento de log do Analytics
                handleWhatsAppClick(); // Chama a função handleWhatsAppClick
              }}
            >
              <FaWhatsapp className="h-6 w-6 mr-1" />
              Solicite seu orçamento gratuito!
              
            </Button>

    </div>
  );
};


const HomeTextScroll = () => {
  const texts = [
    "Expertise Inigualável: O Dr. Cálculo Jurídico domina as minúcias dos procedimentos legais. Sua equipe de peritos certificados utiliza métodos comprovados para garantir a máxima precisão em cada etapa do processo.",
    "Economize Tempo e Recursos: Realizar cálculos complexos pode ser um verdadeiro desafio para muitos advogados, consumindo tempo valioso que poderia ser dedicado a outras tarefas essenciais. Ao contar com os serviços do Dr. Cálculo Jurídico, você delega essa responsabilidade a especialistas, liberando sua equipe para se concentrar em outras demandas críticas do escritório.",
    "Amparo Jurídico Sólido: O Dr. Cálculo Jurídico mantém-se atualizado sobre as mais recentes mudanças e atualizações nas legislações pertinentes. Sua profunda compreensão do arcabouço legal garante que seus cálculos estejam em total conformidade com as exigências do Judiciário, minimizando o risco de questionamentos ou contestações.",
    "Resultados Comprovados: Com um histórico impressionante de mais de 5.000 cálculos judiciais realizados no ano de 2023, o Dr. Cálculo Jurídico possui uma reputação inabalável no mercado. Seus clientes frequentemente relatam ganhos significativos em seus processos, graças à precisão e confiabilidade de seus cálculos."
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, 10000);

    return () => clearInterval(interval);
  }, [texts.length]);

  const handlePrevClick = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + texts.length) % texts.length);
  };

  const handleNextClick = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % texts.length);
  };

  return (
    <div className="dr-calculo-container" style={{ maxHeight:'700px', minHeight:'500px', paddingLeft: '90px', paddingRight: '90px', paddingTop: '90px', position: 'relative', alignItems:'center' }}>
      <Typography variant="h4" className="font-bold mb-2">
        CONHEÇA O DR. CÁLCULO JURÍDICO
      </Typography>
      <div className="text-slider">
        {texts.map((text, index) => (
          <div
            key={index}
            className={`text-item ${activeIndex === index ? 'active' : ''}`}
            style={{ display: activeIndex === index ? 'block' : 'none' }}
          >
            {text}
          </div>
        ))}
      </div>
      <div className="indicators">
        {texts.map((_, index) => (
          <div
            key={index}
            className={`indicator ${activeIndex === index ? 'active' : ''}`}
          ></div>
        ))}
      </div>
      <Box sx={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)' }}>
        <Button size='sm' onClick={handlePrevClick} >
          <ArrowBackIos sx={{ color: 'white' }} />
        </Button>
      </Box>
      <Box sx={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}>
        <Button size='sm' onClick={handleNextClick}>
          <ArrowForwardIos sx={{ color: 'white' }} />
        </Button>
      </Box>
    </div>
  );
};

const Questions = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const questions = [
    {
      question: "Quais os tipos de cálculos que vocês realizam?",
      answer: "Realizamos uma ampla variedade de cálculos, incluindo cálculos trabalhistas, cíveis e previdenciários. No âmbito trabalhista, lidamos com cálculos de rescisão, horas extras, insalubridade, periculosidade, entre outros. Na área cível, atuamos com cálculos de liquidação de sentença, atualização de valores, revisões de contratos, entre outros. Já na esfera previdenciária, fazemos cálculos de aposentadoria, revisões de benefícios, simulações de tempo de contribuição, e mais."
    },
    {
      question: "Qual o valor de investimento para a realização dos cálculos?",
      answer: "O valor do investimento para a realização dos cálculos varia de acordo com a complexidade e especificidade do caso. Nós fornecemos orçamentos personalizados que refletem a quantidade de trabalho e expertise necessários. Este orçamento é oferecido de forma gratuita e sem compromisso, permitindo que você tenha uma visão clara dos custos envolvidos antes de decidir seguir adiante."
    },
    {
      question: "Qual o prazo de entrega?",
      answer: "O prazo de entrega dos cálculos depende diretamente da complexidade e do volume de informações envolvidas no caso. Em média, conseguimos finalizar e entregar os cálculos em até 3 dias úteis. No entanto, para casos mais complexos, este prazo pode ser ajustado. Nosso compromisso é sempre entregar resultados precisos e confiáveis dentro do menor tempo possível, mantendo a qualidade do nosso serviço."
    },
    {
      question: "Do que vocês precisam para realizar os cálculos?",
      answer: "Para realizarmos os cálculos com a máxima precisão e eficiência, precisamos do número do processo ou do processo completo em formato PDF. Isso nos permite acessar todas as informações necessárias para uma análise detalhada e minuciosa. Documentos adicionais, como contratos, recibos e outros comprovantes, também podem ser úteis para garantir que todos os detalhes sejam considerados."
    }
  ];

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-container" style={{ padding: "50px" }}>
      <Typography variant="h5" className="font-bold mb-8">
        DÚVIDAS FREQUENTES
      </Typography>
      {questions.map((item, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleAnswer(index)}>
            <span className="icon">{activeIndex === index ? <SlArrowDown /> : <SlArrowRight />}</span>
            <Typography className="font-bold mb-2">
            {item.question}
            </Typography>
          </div>
          <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
            {item.answer}
          </div>
        </div>
      ))}
    </div>
  );
};



const ContactHome = () => {

  return (
    <div  >
    <div className="text-center mb-4"  style={{ height: '50vh', padding:"50px"}}>
    <Typography variant="h5" className="font-bold mb-2">
          ENTRE EM CONTATO CONOSCO
        </Typography>
        <Typography variant="lead" className="mb-6 text-lg">
          Está enfrentando uma situação jurídica complexa e precisa de orientação especializada? Além dos cálculos disponíveis em nosso site, nossa equipe experiente está pronta para ajudar em casos mais específicos. Agende uma consulta conosco para obter suporte personalizado e soluções eficazes para suas necessidades jurídicas.
        </Typography>
        <Button
          color="green"
          size="lg"
          className="flex items-center justify-center mx-auto"
          onClick={() => {
            logEvent(analytics, 'whatsapp(ContactHome)'); // Adicionar o evento de log do Analytics
            handleWhatsAppClick(); // Chama a função handleWhatsAppClick
          }}
        >
          <FaWhatsapp className="h-6 w-6 mr-2" />
          Fale Conosco pelo WhatsApp
        </Button>
    </div>
    
    <Footer />
    </div>
  );
};



{/*       <div className="box">
        
      <div className="mb-4">
            <Typography variant="h5" className="font-bold mb-2">
              O que fazemos
            </Typography>
            <Typography variant="body1" className="mb-4">
              Nossa plataforma oferece cálculos precisos para diversas áreas do direito, ajudando advogados e profissionais jurídicos a economizar tempo e evitar erros.
            </Typography>
            <img src="https://source.unsplash.com/random/600x300" alt="Cálculo Jurídico" className="w-full h-48 object-cover mb-4" />
            <Typography variant="body1">
              Utilizamos tecnologia de ponta para fornecer resultados rápidos e precisos, sempre alinhados com as mais recentes atualizações legais.
            </Typography>
          </div>
        
        </div> */}
