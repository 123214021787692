import React, { useEffect  } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from "./pages/Main";
import Login from './pages/Login';
import Signup from './pages/Signup';
import LandingPage1 from './pages/LandingPage1';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-56ZFQ5JH'
}


function App() {
  const isDevelopment = process.env.REACT_APP_ENV === 'development';

TagManager.initialize(tagManagerArgs);
console.log(TagManager)
  
  return (
    <Router>
      <div className="App bg-white">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/lp1" element={<LandingPage1 />} />

          {isDevelopment && (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
            </>
          )}
        </Routes>
      </div>
    </Router>
  );
}
export default App;

    /*
               <Route
          path="/home"
          element={
            auth ? (
              <Home setAuth={setAuth} />
            ) : (
              <Navigate to="/login" state={{ from: location }} replace />
            )
          }
        /> */
