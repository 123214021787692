// firebase.js
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, isSupported } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBllsQrjR7sfwSMBGTRTZ7Txg2weNMb3Gw",
    authDomain: "drcalculo-9267e.firebaseapp.com",
    projectId: "drcalculo-9267e",
    storageBucket: "drcalculo-9267e.appspot.com",
    messagingSenderId: "352987353583",
    appId: "1:352987353583:web:e798797f2000e71bbea61a",
    measurementId: "G-S7LMM8JZLS"
  };

// Initialize Firebase
let app = null;
let analytics = null;
const isProduction = process.env.REACT_APP_ENV === 'production';

if (isProduction) {
  isSupported().then((result) => {
    if (result) {
      app = initializeApp(firebaseConfig);
      analytics = getAnalytics(app);
    }
  });
}

export { logEvent, analytics };
