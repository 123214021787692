import React from 'react';

function Settings() {
  return (

<div style={{ textAlign: 'center', margin: '50px' }}>
<h1>Configurações em desenvolvimento</h1>
</div>
);
};

export default Settings;