import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Typography, Input  } from "@material-tailwind/react";


function Precidenciario() {

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [value, setValue] = useState(null, null);
  const [selectedIndex, setSelectedIndex] = useState('');

  const indices = [
    { name: 'Índice 1', value: '0.95' },
    { name: 'Índice 2', value: '1.02' },
    { name: 'Índice 3', value: '0.98' },
    // Adicione mais índices conforme necessário
  ];

  const calculateValues = () => {
    // Implemente a lógica de cálculo aqui
    // Pode usar o startDate, endDate, value, selectedIndex e indices para calcular os valores
    // e adicionar os resultados ao estado tableData
  };


  const data = {
    "resultado": {
      "data": [
        {"resultado":{"data":[{"competencia":"05/2022","diasTrabalhados":12,"indiceValor":"100.00","multaValor":"55.00","salarioMes":"1000.00","salarioAtualizado":"1155.00"},{"competencia":"06/2022","diasTrabalhados":30,"indiceValor":"250.00","multaValor":"137.50","salarioMes":"2500.00","salarioAtualizado":"2887.50"},{"competencia":"07/2022","diasTrabalhados":30,"indiceValor":"250.00","multaValor":"137.50","salarioMes":"2500.00","salarioAtualizado":"2887.50"},{"competencia":"08/2022","diasTrabalhados":30,"indiceValor":"250.00","multaValor":"137.50","salarioMes":"2500.00","salarioAtualizado":"2887.50"},{"competencia":"09/2022","diasTrabalhados":30,"indiceValor":"250.00","multaValor":"137.50","salarioMes":"2500.00","salarioAtualizado":"2887.50"},{"competencia":"10/2022","diasTrabalhados":30,"indiceValor":"250.00","multaValor":"137.50","salarioMes":"2500.00","salarioAtualizado":"2887.50"},{"competencia":"11/2022","diasTrabalhados":30,"indiceValor":"250.00","multaValor":"137.50","salarioMes":"2500.00","salarioAtualizado":"2887.50"},{"competencia":"12/2022","diasTrabalhados":30,"indiceValor":"250.00","multaValor":"137.50","salarioMes":"2500.00","salarioAtualizado":"2887.50"},{"competencia":"01/2023","diasTrabalhados":30,"indiceValor":"250.00","multaValor":"137.50","salarioMes":"2500.00","salarioAtualizado":"2887.50"},{"competencia":"02/2023","diasTrabalhados":30,"indiceValor":"250.00","multaValor":"137.50","salarioMes":"2500.00","salarioAtualizado":"2887.50"},{"competencia":"03/2023","diasTrabalhados":13,"indiceValor":"108.33","multaValor":"59.58","salarioMes":"1083.33","salarioAtualizado":"1251.25"}],"totalSalariosAtualizados":"28393.75","honorarios":"5678.75"}}
      ],
      "totalSalariosAtualizados": "28393.75",
      "honorarios": "5678.75"
    }
  };
  
  // Função para criar um objeto de dados no formato desejado
  function createData(competencia, diasTrabalhados, indiceValor, multaValor, salarioAtualizado) {
    return { competencia, diasTrabalhados, indiceValor, multaValor, salarioAtualizado };
  }
  
  // Converter os dados do JSON para o formato desejado
  const rows = data.resultado.data[0].resultado.data.map(item =>
    createData(
      item.competencia,
      item.diasTrabalhados,
      item.indiceValor,
      item.multaValor,
      item.salarioMes,
      item.salarioAtualizado
    )
  );


  return (

<div style={{     textAlign: 'center', 
    margin: '50px auto', 
    maxWidth: '1000px', 
    width: '100%'  }}>


      <Typography variant="h3" color="blue-gray">
      Cálculo Precidenciário
      </Typography>


<Button sx={{marginTop:'10px'}} onClick={calculateValues} variant="contained">Executar Cálculo</Button>

<Box sx={{ display: 'flex', marginTop:'100px'}}>
    <TableContainer component={Paper}  >
      <Table sx={{ minWidth: 500, maxWidth:700 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Competência</TableCell>
            <TableCell align="center">Dias Trabalhados</TableCell>
            <TableCell align="center">Salário</TableCell>
            <TableCell align="center">Índice</TableCell>
            <TableCell align="center">Multa</TableCell>
            <TableCell align="center">Salário Atualizado</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {rows.map((row) => (
  <TableRow key={row.competencia} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
    <TableCell align="center">{row.competencia}</TableCell>
    <TableCell align="center">{row.diasTrabalhados}</TableCell>
    <TableCell align="center">{row.salarioMes}</TableCell>
    <TableCell align="center">{row.indiceValor}</TableCell>
    <TableCell align="center">{row.multaValor}</TableCell>
    <TableCell align="center">{row.salarioAtualizado}</TableCell>
  </TableRow>
))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
</div>

);
};

export default Precidenciario;